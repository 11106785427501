// fix for checkbox checked styles in FireFox, resolves problem
// with check mark position
.select-dropdown .form-check-input label {
  display: block;
}

select.select-initialized {
  display: none !important;
}

.select-arrow {
  color: #000;
  text-align: center;
  font-size: 0.8rem;
  position: absolute;
  right: 9px;
  top: 9px;

  &:before {
    content: '\25BC';
  }
}

.was-validated .form-control:valid ~ .select-arrow {
  color: #00b74a;
}

.was-validated .form-control:invalid ~ .select-arrow {
  color: #f93154;
}

.select-clear-btn {
  color: black;
  font-size: 1rem;
  position: absolute;
  top: 7px;
  right: 27px;
  cursor: pointer;

  &:focus {
    color: $blue-accent-400;
    outline: none;
  }
}

.form-control-sm ~ .select-clear-btn {
  font-size: 0.8rem;
  top: 4px;
}

.form-control-lg ~ .select-clear-btn {
  top: 11px;
}

.select-dropdown-container {
  z-index: 1070;
}

.select-dropdown {
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin: 0;
  min-width: 100px;
  outline: 0;
  position: relative;
  transform: scaleY(0.8);
  opacity: 0;
  transition: all 0.2s;

  &.open {
    transform: scaleY(1);
    opacity: 1;
  }
}

.select-dropdown > .input-group {
  padding: 10px;
}

.select-label {
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.active {
    transform: translateY(-1rem) translateY(0.1rem) scale(0.8);
  }
}

.form-control-lg ~ .select-label.active {
  transform: translateY(-1.25rem) translateY(0.1rem) scale(0.8);
}

.form-control-sm ~ .select-label.active {
  transform: translateY(-0.83rem) translateY(0.1rem) scale(0.8);
}

.form-outline .select-label.active ~ .form-notch .form-notch-middle {
  border-top: none;
  border-right: none;
  border-left: none;
}

.select-input {
  cursor: pointer;

  &[disabled] {
    cursor: default;
  }
}

.select-input.focused {
  color: #616161;
  outline: 0;

  & ~ .select-label {
    color: #1266f1;
  }

  &::placeholder {
    opacity: 1;
  }

  & ~ .form-notch .form-notch-leading {
    border-top: 2px solid #1266f1;
    border-bottom: 2px solid #1266f1;
    border-left: 2px solid #1266f1;
    transition: all 0.2s linear;
  }

  & ~ .form-notch .form-notch-middle {
    border-bottom: 2px solid;
    border-color: #1266f1;
    transition: all 0.2s linear;
  }

  & ~ .form-notch .form-notch-trailing {
    border-top: 2px solid;
    border-bottom: 2px solid;
    border-right: 2px solid;
    border-color: #1266f1;
    transition: all 0.2s linear;
  }
}

.select-input.focused ~ .select-arrow {
  color: #4285f4;
}

.form-white .select-input:focus ~ .select-arrow {
  color: #fff;
}

.form-white .select-arrow {
  color: #fff;
}

.form-white .select-clear-btn {
  color: #fff;
}

.form-control-sm ~ .select-arrow {
  top: 3px;
}

.form-control-lg ~ .select-arrow {
  top: 13px;
}

.select-options-wrapper {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-button {
    &:start:decrement,
    &:end:increment {
      display: block;
      height: 0;
      background-color: transparent;
    }
  }

  &::-webkit-scrollbar-track-piece {
    background-color: transparent;
    border-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:vertical {
    height: 50px;
    background-color: #999;
    border-radius: 4px;
  }
}

.select-options-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select-option-group-label {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
  color: rgba(0, 0, 0, 0.54);
  user-select: none;
}

.select-option-group > .select-option {
  padding-left: 26px;
}

.select-option {
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.87);
  padding-left: 16px;
  padding-right: 16px;
  font-size: 1rem;
  font-weight: 400;
  background-color: transparent;
  user-select: none;

  &:hover:not(.disabled) {
    background-color: #ddd;
  }

  &.active {
    background-color: #ddd;
  }

  &.selected.active {
    background-color: #ddd;
  }

  &.selected.disabled {
    cursor: default;
    color: #9e9e9e;
    background-color: transparent;
  }

  &.selected {
    background-color: #eee;
  }

  &.disabled {
    cursor: default;
    color: #9e9e9e;
  }
}

.select-option-text {
  & .form-check-input {
    margin-right: 10px;
  }
}

.select-option-secondary-text {
  font-size: 0.8rem;
  color: #6c757d;
  display: block;
  line-height: normal;
}

.select-option-icon {
  width: 28px;
  height: 28px;
}

.select-custom-content {
  padding: 16px;
}

.select-no-results {
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
}
