$utilities: map-merge(
  $utilities,
  (
    'margin-bottom':
      map-merge(
        map-get($utilities, 'margin-bottom'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'margin-bottom'), 'values'),
              (
                6: 3.5rem,
                7: 4rem,
                8: 5rem,
                9: 6rem,
                10: 8rem,
                11: 10rem,
                12: 12rem,
                13: 14rem,
                14: 16rem,
              )
            ),
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'rounded':
      map-merge(
        map-get($utilities, 'rounded'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'rounded'), 'values'),
              (
                4: 0.375rem,
                5: 0.5rem,
                6: 0.75rem,
                7: 1rem,
                8: 1.25rem,
                9: 1.5rem,
              )
            ),
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'opacity': (
      property: opacity,
      values: (
        0: 0,
        5: 0.05,
        10: 0.1,
        20: 0.2,
        30: 0.3,
        40: 0.4,
        50: 0.5,
        60: 0.6,
        70: 0.7,
        80: 0.8,
        90: 0.9,
        95: 0.95,
        100: 1,
      ),
    ),
  )
);

/* Numeric */
.diagonal-fractions {
  font-variant-numeric: diagonal-fractions;
}

/* Background colors */
.bg-super-light {
  background-color: #fbfbfb;
}

/* Background attachment */
.bg-fixed {
  background-attachment: fixed;
}
.bg-local {
  background-attachment: local;
}
.bg-scroll {
  background-attachment: scroll;
}

/* Overflow */
.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

/* Tables */
.table-fixed {
  table-layout: fixed;
}
.table-auto {
  table-layout: auto;
}

$utilities: map-merge(
  $utilities,
  (
    'shadow':
      map-merge(
        map-get($utilities, 'shadow'),
        (
          values:
            map-merge(
              map-get(map-get($utilities, 'shadow'), 'values'),
              (
                0: $box-shadow-0,
                1: $box-shadow-1,
                2: $box-shadow-2,
                3: $box-shadow-3,
                4: $box-shadow-4,
                5: $box-shadow-5,
                6: $box-shadow-6,
                1-soft: $box-shadow-1-soft,
                2-soft: $box-shadow-2-soft,
                3-soft: $box-shadow-3-soft,
                4-soft: $box-shadow-4-soft,
                5-soft: $box-shadow-5-soft,
                6-soft: $box-shadow-6-soft,
                1-strong: $box-shadow-1-strong,
                2-strong: $box-shadow-2-strong,
                3-strong: $box-shadow-3-strong,
                4-strong: $box-shadow-4-strong,
                5-strong: $box-shadow-5-strong,
                6-strong: $box-shadow-6-strong,
                inner: $box-shadow-inner,
              )
            ),
        )
      ),
  )
);

$utilities: map-merge(
  $utilities,
  (
    'letter-spacing': (
      property: letter-spacing,
      class: ls,
      values: (
        tighter: -0.05em,
        tight: -0.025em,
        normal: 0em,
        wide: 0.025em,
        wider: 0.05em,
        widest: 0.1em,
      ),
    ),
  )
);
