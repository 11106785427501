// Your custom styles

.breadcrumb {
  margin-bottom: 0.5rem;
}

.breadcrumb-item a {
  color: $blue-gray-400;
}

.breadcrumb-item.active {
  color: $blue-gray-200;
}

// additional colors
$hreu-colors: (
  'blue-gray-50': $blue-gray-50,
  'blue-gray-100': $blue-gray-100,
  'blue-gray-200': $blue-gray-200,
  'blue-gray-300': $blue-gray-300,
  'blue-gray-400': $blue-gray-400,
  'blue-gray-500': $blue-gray-500,
  'blue-gray-600': $blue-gray-600,
  'blue-gray-700': $blue-gray-700,
  'blue-gray-800': $blue-gray-800,
  'blue-gray-900': $blue-gray-900,
);

@each $color, $value in $hreu-colors {
  .bg-#{$color} {
    background-color: $value !important;
  }
  .#{$color} {
    color: $value !important;
  }
}
