// Your custom variables

@import "~@fontsource/roboto-slab/200.css"; /* light */
@import "~@fontsource/roboto-slab/400.css"; /* regular */
@import "~@fontsource/roboto-slab/600.css"; /* bold */

$font-family-base: "Roboto Slab", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", system-ui, -apple-system, sans-serif;
$font-weight-light:           200 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            600 !default;

$primary: #005c97;
$secondary: #c53030;
